/**
 * Medbook
 * Medbook api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type TrainingType = 'Fellowship' | 'Internship';

export const TrainingType = {
    Fellowship: 'Fellowship' as TrainingType,
    Internship: 'Internship' as TrainingType
};

